import axios from "axios";

//@ts-ignore
// TODO: Figure out a way to type this from the dotenv library
import { BASE_API_URL } from '@env';

const instance = axios.create({ baseURL: BASE_API_URL });



export default instance;
