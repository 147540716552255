import { FontAwesome } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { ScrollView, View, Text, Pressable, Platform, SafeAreaView } from 'react-native';
import { AppButton } from '../components/AppButton';
import { AppModal } from '../components/AppModal';
import { AppHeader } from '../components/AppHeader';
import { ConnectWithUsCard } from '../components/ConnectWithUsCard';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import IconSizes from '../constants/IconSizes';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps } from '../types';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import useIsMobile from '../hooks/useIsMobile';
import Heights from '../constants/Heights';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setAccounts, setAutopayToEnable, setExtensionDate, setLoggedIn, setPaymentAmount, setSelectedAccount, setXdrId } from '../store/user';
import { customerService } from '../services/CustomerService';
import { accountService } from '../services/AccountService';
import { profileService } from '../services/ProfileService';
import { AppCard } from '../components/AppCard';
import { AppTextInput } from '../components/AppTextInput';
import { StepType } from '../types/enums/StepType';
import { ExtensionType } from '../types/enums/ExtensionType';
import { extractExpirationDate, getCreditCardString } from '../utilities/utilities';
import { tokenService } from '../services/TokenService';
import { transactionService } from '../services/TransactionService';
import { AppCreditCard } from '../components/AppCreditCard';
import { submitAsForm } from '../utilities/utilities';

export default function MakeAPaymentScreen({ route, navigation }: RootStackScreenProps<'MakeAPayment'>) {
  const user = useSelector((state: RootState) => state.user);
  const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const [paymentStep, setPaymentStep] = useState(StepType.First);
  const [errorMsg, setErrorMsg] = useState('Something went wrong.');
  const [paymentSuccessfulAmt, setPaymentSuccessfulAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const planEndDate = user.selectedAccount.expiry_date ? new Date(user.selectedAccount.expiry_date) : new Date();
  const [endDateInCaseOfExtension, setEndDateInCaseOfExtension] = useState('');
  const [isAutopayEnabled, setIsAutopayEnabled] = useState(false);
  const [card, setCard] = useState({ number: '', name: '', expirationDate: '', cvv: '', internationalCalling: false, cardType: 'VISA' });
  const dispatch = useDispatch();

  const handleCardNumber = (text: string) => {
    const cardType = getCreditCardString(text);
    setCard({ ...card, number: text, cardType: cardType });
  };

  const getDaysRemaining = (start: Date, end: Date) => {
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  // If it's more than 30 days before the term's end date,
  // we can assume that extension has taken place.
  const checkIfExtended = () => {
    console.log("we are checking if extended");
    let thirtyDaysBeforeEndDate = new Date(planEndDate.toLocaleDateString('en-US'));
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    let now = new Date();
    if (thirtyDaysBeforeEndDate > now) {
      setIsExtended(true);
    }
  }

  // TODO: alter this eventually, not used as of now
  const createEndDateInCaseOfExtension = () => {
    let thirtyDaysBeforeEndDate = new Date(planEndDate);
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    setEndDateInCaseOfExtension(thirtyDaysBeforeEndDate.toISOString().split('T')[0] + 'T04:00:00.000Z');
  }

  const extraFeeCharged = (getDaysRemaining(new Date(), planEndDate) < -30)

  // must be midnight EST formatted as UTC (+4 hours)
  const extendUntil = user.userActions.extensionDate + 'T04:00:00.000Z';
  const expiresOn = user.selectedAccount.expiry_date.split(' ')[0] + 'T04:00:00.000Z';
  const recurringIProduct = user.selectedAccount.addons.id;
  const isExtensionOrder = user.userActions.extensionType === ExtensionType.Extension;
  const isTopupOrder = user.userActions.extensionType === ExtensionType.TopUp;
  const isInternationalCalling = user.userActions.extensionType === ExtensionType.InternationalCalling;
  const isPlanChangeOrder = user.userActions.extensionType === ExtensionType.PlanChange;
  // differentiate this because topUpPaymentAmount/internationalCallingPaymentAmount comes from a separate component from plan change/extension/restoration.
  const paymentAmount = 
    isTopupOrder ? user.userActions.topUpPaymentAmount : 
    isInternationalCalling ? user.userActions.internationalCallingPaymentAmount : user.userActions.paymentAmount;

  const orderTitle = 
    isExtensionOrder ? 'Plan Extension' : 
    isPlanChangeOrder ? `Plan Change - ${user.userActions.planChangeName}` : 
    isTopupOrder ? `${user.userActions.topUpDataAmount} GB Data Top Up` :
    isInternationalCalling ? `International Calling Top Up` : 'Plan Restoration';

  const enableAutoPayment = async () => {

    const response = await profileService.enableAutoPayment({
      data: {
        customer_id: user.selectedAccount.customer_info.customer_id
      }
    })
    
    if (!response || !response.data) {
      setPaymentStep(StepType.Error);
      console.error(response.error);
      setModalVisible(true);
      return;
    }
  }

  const updateCustomFieldsValues = async (xdr_ID: number) => {

    let topUpISubscription;

    if (isInternationalCalling) {
      topUpISubscription = 9
    } else if (isTopupOrder && user.userActions.topUpDataAmount === 1) {
      topUpISubscription = 5
    } else if (isTopupOrder && user.userActions.topUpDataAmount === 2) {
      topUpISubscription = 47
    } else if (isTopupOrder && user.userActions.topUpDataAmount === 3) {
      topUpISubscription = 48
    }

    let dbValue;

    if (isExtensionOrder) {
      dbValue = `{\"extend_until\":\"${extendUntil}\", \"service_i_product\":${user.selectedAccount.plan.id}}`
    } else if (isPlanChangeOrder) {
      dbValue = `{\"order_ref\":\"${xdr_ID}\", \"service_i_product\":${user.userActions.planChangeServiceIProduct}, \"recurring_i_product\":${user.userActions.planChangeRecurringIProduct}}`
    } else if (isTopupOrder || isInternationalCalling) {
      // we consider "International Calling" to be a "Top Up" action      
      dbValue = `{\"order_ref\":${xdr_ID}, \"expires_on\":\"${isExtended ? endDateInCaseOfExtension : expiresOn}\", \"topup_i_subscription\": ${topUpISubscription}}`
    } else if (extraFeeCharged) {
      dbValue = `{\"service_i_product\":${user.selectedAccount.plan.id}, \"recurring_i_product\":${recurringIProduct}, \"recovery_i_subscription\":46, \"order_ref\": ${xdr_ID}}`
    } else {
      dbValue = `{\"service_i_product\":${user.selectedAccount.plan.id}, \"recurring_i_product\":${recurringIProduct}, \"order_ref\": ${xdr_ID}}`
    }

    console.log("real dbValue", dbValue);

    let customFieldName;

    if (isExtensionOrder) {
      customFieldName = "Extension Order";
    } else if (isPlanChangeOrder) {
      customFieldName = "Plan Change Order";
    } else if (isTopupOrder || isInternationalCalling) {
      // we consider "International Calling" to be a "Top Up" action      
      customFieldName = "Topup Order";
    } else {
      customFieldName = "Restoration Order";
    }

    const response = await accountService.updateCustomFieldsValues({
      data: {
        custom_fields_values: [
          {
            db_value: dbValue,
            name: customFieldName
          }
        ],
        i_account: user.selectedAccount.id
      }
    })

    if (!response || !response.data) {
      setPaymentStep(StepType.Error);
      console.error(response.error);
      setModalVisible(true);
      return;
    }
  }

  const handleSavedCreditCardPayment = async () => {
    setLoading(true);

    const response = await customerService.makeTransaction({
      data: {
        action: 'E-Commerce Payment',
        visible_comment: `E-Commerce Payment for ${orderTitle}`,
        amount: paymentAmount,
        i_customer: user.selectedAccount.customer_info.customer_id,
        save_card: user.userActions.autopayToEnable ? 'Y' : 'N'
      }
    })

    if (!response || !response.data) {
      setLoading(false);
      setPaymentStep(StepType.Error);
      console.error(response.error);
      setModalVisible(true);
      return;
    }

    let xdr_ID = response.data.i_xdr;
    console.log('xdr ID: ', xdr_ID);

    setPaymentSuccessfulAmt(paymentAmount);
    dispatch(setPaymentAmount(0));
    
    if(user.userActions.autopayToEnable) {
      await enableAutoPayment()
    }

    dispatch(setAutopayToEnable(false));

    await updateCustomFieldsValues(xdr_ID)

    // delay to allow for boomi working in background
    setTimeout(() => {
      setLoading(false);
      setPaymentStep(StepType.Success);
      setModalVisible(true);
    }, 5000)
  }

  const handleCreditCardPayment = async () => {
    setLoading(true);
    const expiry = extractExpirationDate(card.expirationDate);

    var response = await customerService.makeTransaction({
      data: {
        action: 'E-Commerce Payment',
        visible_comment: `E-Commerce Payment for ${orderTitle}`,
        amount: paymentAmount,
        card_info: {
          cvv: card.cvv,
          exp_month: expiry.expirationMonth,
          exp_year: expiry.expirationYear,
          name: card.name,
          number: card.number,
          payment_method: card.cardType
        },
        i_customer: user.selectedAccount.customer_info.customer_id,
        save_card: user.userActions.autopayToEnable ? 'Y' : 'N'
      }
    });

    if (!response || !response.data) {
      setLoading(false)
      setPaymentStep(StepType.Error);
      console.error(response.error);
      setModalVisible(true);
      return;
    }

    let xdr_ID = response.data.i_xdr;

    console.log('xdr ID: ', xdr_ID);

    setPaymentSuccessfulAmt(paymentAmount);
    dispatch(setPaymentAmount(0));

    if(user.userActions.autopayToEnable) {
      await enableAutoPayment()
    }

    dispatch(setAutopayToEnable(false));
    
    await updateCustomFieldsValues(xdr_ID);

    // delay to allow for boomi working in background
    setTimeout(() => {
      setLoading(false);
      setPaymentStep(StepType.Success);
      setModalVisible(true);
    }, 5000)
  }

  const onPressHome = async () => {
    dispatch(setExtensionDate(""));
    setModalVisible(false); 
    navigation.navigate('Root');
  }

  const onPressTryAgain = async () => {
    dispatch(setExtensionDate(""));
    setModalVisible(false); 
    navigation.navigate('Root');
  }

  useEffect(() => {
    checkIfExtended();
    createEndDateInCaseOfExtension();
  }, [isExtended]);

  const handlePayPalPayment = async () => {
    setLoading(true)
    try {
      const response = await transactionService.payPal({
        data: {
          amount: paymentAmount,
          cancel_url: location.origin,
          customer_id: user.selectedAccount.customer_info.customer_id,
          return_url: `${location.origin}/payment-result?i_payment_transaction={{PayPalTransactionId}}&payment_method=paypal&amount=${paymentAmount}`
        }
      })

      submitAsForm(response.data.pay_url, response.data.payload)

      setPaymentSuccessfulAmt(paymentAmount);
      dispatch(setPaymentAmount(0));
  
      let custom = response.data.payload.custom;
      let xdr_ID_match = custom.match(/(\d+)/);
  
      if(xdr_ID_match){
        setLoading(false);
        let xdr_ID = +xdr_ID_match[0];
        dispatch(setXdrId(xdr_ID));
        console.log('xdr ID:', user.xdrId);
      } else {
        setLoading(false)
        setPaymentStep(StepType.Error);
        console.error(response.error);
        return;
      }
    } catch (err) {
      setLoading(false)
      setPaymentStep(StepType.Error);
      console.error(err);
      setModalVisible(true);
      return;
    }
  }

  useEffect(() => {
    if (paymentStep === StepType.Success) {
      const getAccounts = async () => {
        const accountInfoResponse = await profileService.getAccountInfo({ data: {} })
        if (!accountInfoResponse || !accountInfoResponse.success || accountInfoResponse.data.accounts.length === 0) {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRefreshTokenExpiryAsync();
          dispatch(setLoggedIn(false));
          console.error(accountInfoResponse.error);
          return;
        }
  
        // TODO: In the future we can support multiple accounts/plans
        // For now just grab the first one
        const account = accountInfoResponse.data.accounts[0];
        dispatch(setAccounts(accountInfoResponse.data.accounts));
        dispatch(setSelectedAccount(account));
      }
      getAccounts().catch(err => console.error(err));
    }
  }, [paymentStep]);

  useEffect(() => {
    if(user.selectedAccount.customer_info.auto_payment){
      setIsAutopayEnabled(true);
    } else {
      setIsAutopayEnabled(false);
    }
  }, [user.selectedAccount.customer_info.auto_payment]);


  return (
    <>
      {!isMobile && 
        <>
          <AppHeader></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginRight: Sizes.large }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Make a Payment</Text>
                  <View style={{ ...CommonStyles.card, borderColor: Colors.green, borderWidth: 1, borderRadius: 10, backgroundColor: Colors.white }}>
                    <Text style={{ ...TextStyles.subtitle, color: Colors.green }}>
                      {orderTitle}
                    </Text>
                    <Text style={{ ...TextStyles.title, color: Colors.green }}>
                      ${paymentAmount.toFixed(2)}
                    </Text>
                  </View>
                  {!isAutopayEnabled &&
                    <>
                      <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Payment Method</Text>
                      <View style={{ marginBottom: Sizes.medium }}>
                        <AppCard>
                          <Pressable onPress={() => handlePayPalPayment()}>
                            <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                              <FontAwesome name="paypal" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                              <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                                PayPal
                              </Text>
                            </View>
                          </Pressable>
                        </AppCard>
                      </View>
                        <AppCard>
                          <Pressable onPress={() => setModalVisible(true)}>
                            <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                              <FontAwesome name="credit-card-alt" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                              <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                                Credit Card
                              </Text>
                            </View>
                          </Pressable>
                        </AppCard>
                      
                      <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                        <AppButton text="Back" onPress={() => navigation.navigate('Root')}></AppButton>
                      </View>
                    </>
                  }
                  {isAutopayEnabled &&
                    <>
                      {!otherPaymentMethod && 
                        <>
                          <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Saved Credit Card</Text>
                          { user.selectedAccount.customer_info.payment_method_info &&
                            <AppCreditCard 
                              cardHolderName={user.selectedAccount.customer_info.payment_method_info.card_holder_name} 
                              expiryMonth={user.selectedAccount.customer_info.payment_method_info.expiry_month} 
                              expiryYear={user.selectedAccount.customer_info.payment_method_info.expiry_year} 
                              maskedCardNumber={user.selectedAccount.customer_info.payment_method_info.masked_card_number} 
                              type={user.selectedAccount.customer_info.payment_method_info.card_type} 
                            />
                          }
                          <View style={{ marginTop: Sizes.large, minWidth: 300 }}>
                            <AppButton text={loading ? "Please wait..." : "Pay Now"} loading={loading} onPress={() => {handleSavedCreditCardPayment()}}></AppButton>
                          </View>
                          <View style={{ marginTop: Sizes.medium }}>
                            <AppButton text="Other Payment Method" outline={true} color={Colors.green} onPress={() => { setOtherPaymentMethod(true)}}></AppButton>
                          </View>
                        </>
                      }
                      {otherPaymentMethod &&
                        <>
                          <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Payment Method</Text>
                          {/* <View style={{ marginBottom: Sizes.medium }}>
                            <AppCard>
                              <Pressable onPress={() => handlePayPalPayment()}>
                                <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <FontAwesome name="paypal" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                                  <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                                    PayPal
                                  </Text>
                                </View>
                              </Pressable>
                            </AppCard>
                          </View> */}
                          <AppCard>
                            <Pressable onPress={() => setModalVisible(true)}>
                              <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FontAwesome name="credit-card-alt" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                                <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                                  Credit Card
                                </Text>
                              </View>
                            </Pressable>
                          </AppCard>
                          <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                            <AppButton text="Back" onPress={() => setOtherPaymentMethod(false)}></AppButton>
                          </View>
                        </>
                      }
                    </>
                  }
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ marginLeft: Sizes.large }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Connect With Us</Text>
                  <ConnectWithUsCard />
                </View>
              </View>
              <AppModal onClose={() => {paymentStep === StepType.Success ? onPressHome() : setModalVisible(false)}} visible={modalVisible}>
                {(paymentStep === StepType.First) && 
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <View style={{ flex: 1 }}>
                      <View style={{ marginRight: Sizes.large, marginVertical: 'auto' }}>
                        <Text style={{ ...TextStyles.heading, textAlign: 'center' }}>
                          Your total amount in US Dollars is:
                        </Text>
                        <Text style={{ ...TextStyles.title, color: Colors.green, textAlign: 'center' }}>
                          USD ${paymentAmount}
                        </Text>
                      </View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <View style={{ marginLeft: Sizes.large }}>
                        <View>
                          <View>
                            <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.small }}>Card Number</Text>
                            <AppTextInput placeholder='1234567812345678' onChangeText={(text) => handleCardNumber(text)} maxLength={16} numbersOnly
                            ></AppTextInput>
                          </View>
                          <View style={{ marginVertical: Sizes.medium }}>
                            <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.small }}>Name on Card</Text>
                            <AppTextInput
                              placeholder='James Smith'
                              onChangeText={(text) => setCard({...card, name: text })}
                            ></AppTextInput>
                          </View>
                          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <View style={{ marginRight: Sizes.small }}>
                              <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.small }}>Expiration Date</Text>
                              <AppTextInput
                                placeholder='MM/YY'
                                onChangeText={(text) => setCard({...card, expirationDate: text })}
                                maxLength={5} 
                                numbersOnly 
                                expiryDate
                              ></AppTextInput>
                            </View>
                            <View style={{ marginLeft: Sizes.small }}>
                              <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.small }}>CVV</Text>
                              <AppTextInput
                                onChangeText={(text) => setCard({...card, cvv: text })}
                                maxLength={4}
                                numbersOnly
                                placeholder='XXXX'
                              ></AppTextInput>
                            </View>
                          </View>
                        </View>
                        <View style={{ marginTop: Sizes.large }}>
                          <AppButton text={loading ? "Please wait..." : "Confirm and Pay"} color={Colors.blue} loading={loading} onPress={() => { handleCreditCardPayment() }}></AppButton>
                        </View>
                        <View style={{ marginTop: Sizes.medium }}>
                          <AppButton text="Cancel and Return" onPress={() => { setModalVisible(false); navigation.pop(2); }}></AppButton>
                        </View>
                      </View>
                    </View>
                  </View>
                }
                {(paymentStep === StepType.Success) &&
                  <View style={{ flex: 1, flexDirection: 'column', width: 550, paddingHorizontal: 80, paddingVertical: 30 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.blue, textAlign: 'center' }}>Payment Sucessful!</Text>
                    {user.userActions.extensionType !== ExtensionType.TopUp &&
                      <Text style={{ ...TextStyles.paragraph, textAlign: 'center', marginTop: Sizes.medium }}>Your services will be active until <Text style={{ fontFamily: FontFamilys.bold }}>{user.userActions.extensionDate}</Text>.</Text>
                    }
                    <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>It may take a few moments for these changes to be reflected on your account.</Text>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.large }}>
                      <Text style={{ ...TextStyles.paragraph }}>Payment Type</Text>
                      <Text style={{ ...TextStyles.paragraph }}>XXXX XXXX XXXX {card.number.slice(-4)}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.medium }}>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Amount Paid</Text>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>${paymentSuccessfulAmt.toFixed(2)}</Text>
                    </View>
                    <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                      <AppButton text="Home" color={Colors.blue} onPress={() => onPressHome()}></AppButton>
                    </View>
                  </View>
                } 
                {(paymentStep === StepType.Error) &&
                  <View style={{ flex: 1, flexDirection: 'column', width: 550, paddingHorizontal: 80, paddingVertical: 30 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.red, textAlign: 'center' }}>Payment Failed</Text>
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: Sizes.large }}>
                      <Text style={{ ...TextStyles.paragraph, marginHorizontal: 'auto' }}>{errorMsg}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: Sizes.medium }}>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, marginHorizontal: 'auto' }}>Please retry the payment once again.</Text>
                    </View>
                    <View style={{ marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                      <AppButton text="Try Again" color={Colors.blue} onPress={() => onPressTryAgain()}></AppButton>
                    </View>
                  </View>
                } 
              </AppModal>
            </View>
          </ScrollView>
        </>
      }
      {isMobile && 
        <ScrollView>
        <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
          <Text style={{ ...TextStyles.title, color: Colors.white, marginBottom: Sizes.large }}>Make a Payment</Text>
          <View style={{ ...CommonStyles.card, marginBottom: Sizes.large }}>
            <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center' }}>
              {orderTitle}
            </Text>
            <Text style={{ ...TextStyles.title, color: Colors.green, textAlign: 'center' }}>${paymentAmount.toFixed(2)}</Text>
          </View>
          {!isAutopayEnabled &&
            <>
              <Text style={{ ...TextStyles.heading, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Payment Method</Text>
              <View style={{ ...CommonStyles.card, padding: 0, marginBottom: Sizes.large }}>
                {/* <Pressable onPress={() => handlePayPalPayment()}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <FontAwesome name="paypal" size={25}></FontAwesome>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15 }}>PayPal</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable> */}
                <Pressable onPress={() => setModalVisible(true)}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <FontAwesome name="credit-card-alt" size={25}></FontAwesome>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15 }}>Credit Card</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable>
              </View>
              <AppButton text="Back" onPress={() => navigation.navigate("Root")} color={Colors.white} outline></AppButton>
            </>
          }
          {isAutopayEnabled &&
            <>
              {!otherPaymentMethod && 
                <>
                  <Text style={{ ...TextStyles.heading, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Saved Credit Card</Text>
                  { user.selectedAccount.customer_info.payment_method_info &&
                    <AppCreditCard 
                      cardHolderName={user.selectedAccount.customer_info.payment_method_info.card_holder_name} 
                      expiryMonth={user.selectedAccount.customer_info.payment_method_info.expiry_month} 
                      expiryYear={user.selectedAccount.customer_info.payment_method_info.expiry_year} 
                      maskedCardNumber={user.selectedAccount.customer_info.payment_method_info.masked_card_number} 
                      type={user.selectedAccount.customer_info.payment_method_info.card_type} 
                      textColor={Colors.black}
                      backgroundColor={Colors.white}
                    />
                  }
                  <View style={{ marginTop: Sizes.large }}>
                    <AppButton text={loading ? "Please wait..." : "Pay Now"} loading={loading} onPress={() => {handleSavedCreditCardPayment()}}></AppButton>
                  </View>
                  <View style={{ marginTop: Sizes.medium }}>
                    <AppButton text="Other Payment Method" outline={true} color={Colors.white} onPress={() => { setOtherPaymentMethod(true)}}></AppButton>
                  </View>
                </>
              }
              {otherPaymentMethod &&
                <>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.small }}>Other Payment Method</Text>
                  <View style={{ ...CommonStyles.card, padding: 0, marginBottom: Sizes.large }}>
                    {/* <Pressable onPress={() => handlePayPalPayment()}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15, borderTopColor: Colors.lightGrey, borderTopWidth: 1 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                          <FontAwesome name="paypal" size={25}></FontAwesome>
                          <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15, marginLeft: Sizes.medium }}>PayPal</Text>
                        </View>
                        <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                      </View>
                    </Pressable> */}
                    <Pressable onPress={() => setModalVisible(true)}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                          <FontAwesome name="credit-card-alt" size={25}></FontAwesome>
                          <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15 }}>Credit Card</Text>
                        </View>
                        <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                      </View>
                    </Pressable>
                  </View>
                  <AppButton text="Back" onPress={() => setOtherPaymentMethod(false)} color={Colors.white} outline></AppButton>
                </>
              }
            </>
          }

          <AppModal onClose={() => {paymentStep === StepType.Success ? onPressHome() : setModalVisible(false)}} visible={modalVisible} width={370}>
            {(paymentStep === StepType.First) && 
              <View>
                <View>
                  <Text style={{ ...TextStyles.heading, marginBottom: 5 }}>Card Number</Text>
                  <AppTextInput placeholder='1234567812345678' onChangeText={(text) => handleCardNumber(text)} maxLength={16} numbersOnly
                  ></AppTextInput>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <Text style={{ ...TextStyles.heading, marginBottom: 5 }}>Name on Card</Text>
                  <AppTextInput
                    placeholder='James Smith'
                    onChangeText={(text) => setCard({...card, name: text })}
                  ></AppTextInput>
                </View>
                <View>
                  <View>
                    <Text style={{ ...TextStyles.heading, marginBottom: 5 }}>Expiration Date</Text>
                    <AppTextInput
                      placeholder='MM/YY'
                      onChangeText={(text) => setCard({...card, expirationDate: text })}
                      maxLength={5} 
                      numbersOnly 
                      expiryDate
                    ></AppTextInput>
                  </View>
                  <View>
                    <Text style={{ ...TextStyles.heading, marginBottom: 5 }}>CVV</Text>
                    <AppTextInput
                      onChangeText={(text) => setCard({...card, cvv: text })}
                      maxLength={4}
                      numbersOnly
                      placeholder='XXXX'
                    ></AppTextInput>
                  </View>
                </View>
                <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                  <AppButton text={loading ? "Please wait..." : "Confirm and Pay"} color={Colors.blue} loading={loading} onPress={() => { handleCreditCardPayment() }}></AppButton>
                </View>
                <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.medium }}>
                  <AppButton text="Cancel and Return" onPress={() => { setModalVisible(false); navigation.pop(2); }}></AppButton>
                </View>
              </View>
            }
            {(paymentStep === StepType.Success) &&
              <View style={{ height: 250 }}>
                <Text style={{ ...TextStyles.heading, color: Colors.green, textAlign: 'center' }}>Payment Sucessful!</Text>
                {user.userActions.extensionType !== ExtensionType.TopUp &&
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', marginTop: Sizes.medium }}>Your services will be active until <Text style={{ fontFamily: FontFamilys.bold }}>{user.userActions.extensionDate}</Text>.</Text>
                }
                <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>It may take a few moments for these changes to be reflected on your account.</Text>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: Sizes.large }}>
                  <Text style={{ ...TextStyles.paragraph }}>Payment Type</Text>
                  <Text style={{ ...TextStyles.paragraph }}>XXXX XXXX XXXX {card.number.slice(-4)}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.small }}>
                  <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Amount Paid</Text>
                  <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>${paymentSuccessfulAmt.toFixed(2)}</Text>
                </View>
                <View style={{  ...FlexStyles.rowCenter, marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                  <AppButton text="Home" color={Colors.red} onPress={() => { setModalVisible(false); navigation.navigate('Root'); }}></AppButton>
                </View>
              </View>
            } 
            {(paymentStep === StepType.Error) &&
              <View style={{ height: 170 }}>
                <Text style={{ ...TextStyles.heading, color: Colors.red, textAlign: 'center' }}>Payment Failed</Text>
                <View style={{ paddingTop: Sizes.large }}>
                  <Text style={{ ...TextStyles.paragraph, marginHorizontal: 'auto', textAlign: 'center' }}>{errorMsg}</Text>
                </View>
                <View style={{ paddingTop: Sizes.medium }}>
                  <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, marginHorizontal: 'auto', textAlign: 'center' }}>Please retry the payment once again.</Text>
                </View>
                <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.extraLarge, marginHorizontal: 'auto' }}>
                  <AppButton text="Try Again" color={Colors.red} onPress={() => onPressTryAgain()}></AppButton>
                </View>
              </View>
            } 
          </AppModal>
        </View>
        </ScrollView>      
      }
    </>
  );
}