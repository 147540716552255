import { useState } from 'react';
import { ScrollView, StyleSheet, View, Text, Pressable, Switch } from 'react-native';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps } from '../types';
import { PlanExtensionCard } from '../components/PlanExtensionCard';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import FlexStyles from '../constants/FlexStyles';
import { setExtensionDate, setExtensionType } from '../store/user';
import { ExtensionType } from '../types/enums/ExtensionType';

export default function ExtendYourPlanScreen({ navigation }: RootStackScreenProps<'ExtendYourPlan'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => setIsEnabled(previousState => !previousState);
  const now = new Date();
  const planEndDate = new Date(user.selectedAccount.expiry_date);
  const planExpired = now > planEndDate;

  const newEndDate = new Date(user.selectedAccount.expiry_date);
  newEndDate.setDate(newEndDate.getDate() + 30);
  const formattedDateString = !isNaN(newEndDate.getTime()) ? newEndDate.toISOString().split('T')[0] : null;

  // restoration date setting
  let restoreDate = new Date();
  restoreDate.setDate(restoreDate.getDate() + 30);
  const formattedRestoreDate = !isNaN(restoreDate.getTime()) ? restoreDate.toISOString().split('T')[0] : null;

  // plan change settings
  const planHasBeenChanged = (user.selectedAccount.future_plan && user.selectedAccount.future_plan?.end_date !== null && user.selectedAccount.future_plan?.start_date !== null);
  const getDaysRemaining = (start: Date, end: Date) => {
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  const lessThan2DaysRemaining = getDaysRemaining(new Date(), new Date(user.selectedAccount.plan.end_date)) < 2;

  const is7daysPlan = user.selectedAccount.plan.term_days === 7;  
  const allowPlanChange = !planHasBeenChanged && !lessThan2DaysRemaining && !is7daysPlan;

  const onPressConfirm = () => {
    if (!planExpired && formattedDateString) {
      dispatch(setExtensionDate(formattedDateString));
      dispatch(setExtensionType(ExtensionType.Extension));
    } else if (formattedRestoreDate) {
      dispatch(setExtensionDate(formattedRestoreDate));
      dispatch(setExtensionType(ExtensionType.Restoration));
    } else {
      return;
    }

    navigation.navigate('MakeAPayment');
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white, marginBottom: Sizes.medium }}>Extend Your Plan</Text>
        <View style={{ borderRadius: Sizes.medium, marginBottom: Sizes.large }}>
          <PlanExtensionCard
            endDate={new Date(user.selectedAccount.expiry_date)}
            price={user.selectedAccount.plan.price}
            allowExtension={true}
            extendDetailsVisible={true}
            is7daysPlan={is7daysPlan}
            allowPlanChange={allowPlanChange}
          ></PlanExtensionCard>
          {/* <View style={{ backgroundColor: Colors.black, height: 40, borderTopLeftRadius: Sizes.medium, borderTopRightRadius: Sizes.medium, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ color: Colors.white, fontFamily: FontFamilys.regular }}>Expiration Date: <AppBoldText>2022-07-03</AppBoldText></Text>
          </View>
          <View style={{ ...CommonStyles.card, borderTopRightRadius: Sizes.none, borderTopLeftRadius: Sizes.none }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: Sizes.large }}>
              <View style={{ flex: 1 }}>
                <View style={{ width: 82, height: 60, borderRadius: Sizes.medium, backgroundColor: Colors.dimGreen, paddingHorizontal: Sizes.medium, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green }}>12</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.green }}>Days Left</Text>
                </View>
              </View>
              <View style={{ flexShrink: 1, flex: 2 }}>
                <Text style={{ ...TextStyles.paragraph }}>Current Term End Date:</Text>
                <Text style={{ ...TextStyles.paragraph }}><AppBoldText>{"{"}ExpirationDate{"}"}</AppBoldText></Text>
              </View>
            </View>
            <Text style={{ ...TextStyles.small, marginBottom: Sizes.large }}>*You are able to retrieve your phone number up to 90 days after your term expiration date (term end date). There is no reactivation fee within the first 30 days after the expiration. After30 days, the reactivation fee (service charge) will be USD 10 on top of your plan fee. </Text>
            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.green, textAlign: 'center', }}>New Expiration Date: 2022-08-16</Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.black, textAlign: 'center' }}>Plan Fee: <AppBoldText>$35.00</AppBoldText></Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.black, textAlign: 'center' }}>Current Balance: <AppBoldText>$0.00</AppBoldText></Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.bold }}>Total: $35.00 USD</Text>
            <AppSwitch onToggle={(toggled) => setIsEnabled(toggled)} text="Set up Automatic Extension"></AppSwitch>
          </View> */}
        </View>
        <View style={{ ...FlexStyles.rowCenter }}>
          <AppButton text="Confirm" onPress={() => onPressConfirm()}></AppButton>
        </View>
      </View> 
    </ScrollView>
  );
}