import { useState, useEffect , useRef} from "react";
import { View, ScrollView, Text, SafeAreaView, Platform } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../store';
import { ExtensionType } from '../types/enums/ExtensionType';
import { accountService } from '../services/AccountService';
import { StepType } from '../types/enums/StepType';
import useIsMobile from "../hooks/useIsMobile";
import { FontAwesome } from '@expo/vector-icons';
import { AppButton } from '../components/AppButton';
import { AppModal } from '../components/AppModal';
import { AppHeader } from '../components/AppHeader';
import { ConnectWithUsCard } from '../components/ConnectWithUsCard';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import Heights from '../constants/Heights';
import { AppCard } from '../components/AppCard';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { setExtensionDate, setLoggedIn, setAccounts, setSelectedAccount, setXdrId, setPaymentAmount } from '../store/user';
import { RootStackScreenProps } from '../types';
import { profileService } from '../services/ProfileService';
import { tokenService } from '../services/TokenService';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import IconSizes from "../constants/IconSizes";

export default function PaymentResultScreen({ route, navigation }: RootStackScreenProps<'PaymentResult'>) {

  const user = useSelector((state: RootState) => state.user);
  const xdr_ID = user.xdrId;
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const [isExtended, setIsExtended] = useState(false);
  const [endDateInCaseOfExtension, setEndDateInCaseOfExtension] = useState('');
  const planEndDate = new Date(user.selectedAccount.expiry_date);
  const [paymentStep, setPaymentStep] = useState(StepType.First);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('Something went wrong.');
  const paymentSuccessfulAmt = route.params.amount;


  const getDaysRemaining = (start: Date, end: Date) => {
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  // If it's more than 30 days before the term's end date,
  // we can assume that extension has taken place.
  const checkIfExtended = () => {
    let thirtyDaysBeforeEndDate = new Date(planEndDate.toLocaleDateString('en-US'));
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    let now = new Date();
    if (thirtyDaysBeforeEndDate > now) {
      setIsExtended(true);
    }
  }

  // TODO: alter this eventually, not used as of now
  const createEndDateInCaseOfExtension = () => {
    let thirtyDaysBeforeEndDate = new Date(planEndDate.toLocaleDateString('en-US'));
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    setEndDateInCaseOfExtension(thirtyDaysBeforeEndDate.toISOString().split('T')[0] + 'T04:00:00.000Z');
  }

  const extraFeeCharged = (getDaysRemaining(new Date(), planEndDate) < -30)

  // must be midnight EST formatted as UTC (+4 hours)
  const extendUntil = user.userActions.extensionDate + 'T04:00:00.000Z';
  const expiresOn = user.selectedAccount.expiry_date.split(' ')[0] + 'T04:00:00.000Z';
  const recurringIProduct = user.selectedAccount.addons.id;
  const isExtensionOrder = user.userActions.extensionType === ExtensionType.Extension;
  const isTopupOrder = user.userActions.extensionType === ExtensionType.TopUp;
  const isInternationalCalling = user.userActions.extensionType === ExtensionType.InternationalCalling;
  const isPlanChangeOrder = user.userActions.extensionType === ExtensionType.PlanChange;

  const orderTitle = 
    isExtensionOrder ? 'Plan Extension' : 
    isPlanChangeOrder ? `Plan Change - ${user.userActions.planChangeName}` : 
    isTopupOrder ? `${user.userActions.topUpDataAmount} GB Data Top Up` :
    isInternationalCalling ? `International Calling Top Up` : 'Plan Restoration';

  console.log("expires on: ", expiresOn);

  const updateCustomFieldsValues = async (xdr_ID: number) => {

    let topUpISubscription;

    if (user.userActions.topUpDataAmount === 1) {
      topUpISubscription = 5
    } else if (user.userActions.topUpDataAmount === 2) {
      topUpISubscription = 47
    } else if (user.userActions.topUpDataAmount === 3) {
      topUpISubscription = 48
    } else if (isInternationalCalling) {
      topUpISubscription = 9
    }

    let dbValue;

    if (isExtensionOrder) {
      dbValue = `{\"extend_until\":\"${extendUntil}\", \"service_i_product\":${user.selectedAccount.plan.id}}`
    } else if (isPlanChangeOrder) {
      dbValue = `{\"order_ref\":\"${xdr_ID}\", \"service_i_product\":${user.userActions.planChangeServiceIProduct}, \"recurring_i_product\":${user.userActions.planChangeRecurringIProduct}}`
    } else if (isTopupOrder || isInternationalCalling) {
      // we consider "International Calling" to be a "Top Up" action      
      dbValue = `{\"order_ref\":${xdr_ID}, \"expires_on\":\"${isExtended ? endDateInCaseOfExtension : expiresOn}\", \"topup_i_subscription\": ${topUpISubscription}}`
    } else if (extraFeeCharged) {
      dbValue = `{\"service_i_product\":${user.selectedAccount.plan.id}, \"recurring_i_product\":${recurringIProduct}, \"recovery_i_subscription\":46, \"order_ref\": ${xdr_ID}}`
    } else {
      dbValue = `{\"service_i_product\":${user.selectedAccount.plan.id}, \"recurring_i_product\":${recurringIProduct}, \"order_ref\": ${xdr_ID}}`
    }

    console.log("real dbValue", dbValue);

    let customFieldName;

    if (isExtensionOrder) {
      customFieldName = "Extension Order";
    } else if (isPlanChangeOrder) {
      customFieldName = "Plan Change Order";
    } else if (isTopupOrder || isInternationalCalling) {
      // we consider "International Calling" to be a "Top Up" action      
      customFieldName = "Topup Order";
    } else {
      customFieldName = "Restoration Order";
    }

    const response = await accountService.updateCustomFieldsValues({
      data: {
        custom_fields_values: [
          {
            db_value: dbValue,
            name: customFieldName
          }
        ],
        i_account: user.selectedAccount.id
      }
    })

    if (!response || !response.data) {
      setPaymentStep(StepType.Error);
      console.error(response.error);
      setModalVisible(true);
      return;
    }
  }

  useEffect(() => {
    if(!xdr_ID || xdr_ID === 0) {
      setPaymentStep(StepType.Error);
      setModalVisible(true);
      return;
    } else {
      updateCustomFieldsValues(xdr_ID);
      setPaymentStep(StepType.Success);
      setModalVisible(true);
    }
  }, [xdr_ID])

  const onPressHome = async () => {
    dispatch(setExtensionDate(""));
    setModalVisible(false); 
    navigation.navigate('Root');
    dispatch(setXdrId(0));
  }

  useEffect(() => {
    checkIfExtended();
    createEndDateInCaseOfExtension();
  }, [isExtended]);

  useEffect(() => {
    if (paymentStep === StepType.Success) {
      const getAccounts = async () => {
        const accountInfoResponse = await profileService.getAccountInfo({ data: {} })
        if (!accountInfoResponse || !accountInfoResponse.success || accountInfoResponse.data.accounts.length === 0) {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRefreshTokenExpiryAsync();
          dispatch(setLoggedIn(false));
          console.error(accountInfoResponse.error);
          return;
        }
  
        // TODO: In the future we can support multiple accounts/plans
        // For now just grab the first one
        const account = accountInfoResponse.data.accounts[0];
        dispatch(setAccounts(accountInfoResponse.data.accounts));
        dispatch(setSelectedAccount(account));
      }
      getAccounts().catch(err => console.error(err));
    }
  }, [paymentStep]);

  return (
    <>
      {!isMobile && 
        <>
          <AppHeader></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginRight: Sizes.large }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Make a Payment</Text>
                  <View style={{ ...CommonStyles.card, borderColor: Colors.green, borderWidth: 1, borderRadius: 10, backgroundColor: Colors.white }}>
                    <Text style={{ ...TextStyles.subtitle, color: Colors.green }}>
                      {orderTitle}
                    </Text>
                    <Text style={{ ...TextStyles.title, color: Colors.green }}>
                      ${paymentSuccessfulAmt}
                    </Text>
                  </View>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Payment Method</Text>
                  <View style={{ marginBottom: Sizes.medium }}>
                    <AppCard>
                      <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FontAwesome name="paypal" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                        <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                          PayPal
                        </Text>
                      </View>
                    </AppCard>
                  </View>
                    <AppCard>
                      <View style={{ ...CommonStyles.cardBody, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FontAwesome name="credit-card-alt" style={{ color: Colors.blue }} size={Sizes.large} ></FontAwesome>
                        <Text style={{ ...TextStyles.heading, fontFamily: FontFamilys.regular }}>
                          Credit Card
                        </Text>
                      </View>
                    </AppCard>
                  <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                    <AppButton text="Back" onPress={() => navigation.navigate('Root')}></AppButton>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ marginLeft: Sizes.large }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Connect With Us</Text>
                  <ConnectWithUsCard />
                </View>
              </View>
              <AppModal onClose={() => onPressHome()} visible={modalVisible}>
                {(paymentStep === StepType.Success) &&
                  <View style={{ flex: 1, flexDirection: 'column', width: 550, paddingHorizontal: 80, paddingVertical: 30 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.blue, textAlign: 'center' }}>Payment Sucessful!</Text>
                    {user.userActions.extensionType !== ExtensionType.TopUp &&
                      <Text style={{ ...TextStyles.paragraph, textAlign: 'center', marginTop: Sizes.medium }}>Your services will be active until <Text style={{ fontFamily: FontFamilys.bold }}>{user.userActions.extensionDate}</Text>.</Text>
                    }
                    <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>It may take a few moments for these changes to be reflected on your account.</Text>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.large }}>
                      <Text style={{ ...TextStyles.paragraph }}>Payment Type</Text>
                      <Text style={{ ...TextStyles.paragraph }}>PayPal</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.medium }}>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Amount Paid</Text>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>${paymentSuccessfulAmt}</Text>
                    </View>
                    <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                      <AppButton text="Home" color={Colors.blue} onPress={() => onPressHome()}></AppButton>
                    </View>
                  </View>
                } 
                {(paymentStep === StepType.Error) &&
                  <View style={{ flex: 1, flexDirection: 'column', width: 550, paddingHorizontal: 80, paddingVertical: 30 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.red, textAlign: 'center' }}>Payment Failed</Text>
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: Sizes.large }}>
                      <Text style={{ ...TextStyles.paragraph, marginHorizontal: 'auto' }}>{errorMsg}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: Sizes.medium }}>
                      <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, marginHorizontal: 'auto' }}>Please retry the payment once again.</Text>
                    </View>
                    <View style={{ marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                      <AppButton text="Try Again" color={Colors.blue} onPress={() => onPressHome()}></AppButton>
                    </View>
                  </View>
                } 
              </AppModal>
            </View>
          </ScrollView>
        </>
      }
      {isMobile && 
        <>
          {Platform.OS === "web" && 
            <ScrollView>
              <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
                <Text style={{ ...TextStyles.title, color: Colors.white, marginBottom: Sizes.large }}>Make a Payment</Text>
                <View style={{ ...CommonStyles.card, marginBottom: Sizes.large }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center' }}>
                    {orderTitle}
                  </Text>
                  <Text style={{ ...TextStyles.title, color: Colors.green, textAlign: 'center' }}>${paymentSuccessfulAmt}</Text>
                </View>
                <Text style={{ ...TextStyles.heading, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Payment Method</Text>
                <View style={{ ...CommonStyles.card, padding: 0, marginBottom: Sizes.large }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <FontAwesome name="paypal" size={25}></FontAwesome>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15 }}>PayPal</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <FontAwesome name="credit-card-alt" size={25}></FontAwesome>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black, paddingVertical: Sizes.medium, paddingLeft: Sizes.large, fontSize: 15 }}>Credit Card</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </View>
                <AppButton text="Back" onPress={() => navigation.navigate("Root")} color={Colors.white} outline></AppButton>

                <AppModal onClose={() => onPressHome()} visible={modalVisible} width={370}>
                  {(paymentStep === StepType.Success) &&
                    <View style={{ height: 250 }}>
                      <Text style={{ ...TextStyles.heading, color: Colors.green, textAlign: 'center' }}>Payment Sucessful!</Text>
                      {user.userActions.extensionType !== ExtensionType.TopUp &&
                        <Text style={{ ...TextStyles.paragraph, textAlign: 'center', marginTop: Sizes.medium }}>Your services will be active until <Text style={{ fontFamily: FontFamilys.bold }}>{user.userActions.extensionDate}</Text>.</Text>
                      }
                      <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>It may take a few moments for these changes to be reflected on your account.</Text>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: Sizes.large }}>
                        <Text style={{ ...TextStyles.paragraph }}>Payment Type</Text>
                        <Text style={{ ...TextStyles.paragraph }}>PayPal</Text>
                      </View>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.small }}>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Amount Paid</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>${paymentSuccessfulAmt}</Text>
                      </View>
                      <View style={{  ...FlexStyles.rowCenter, marginTop: Sizes.large, marginHorizontal: 'auto' }}>
                        <AppButton text="Home" color={Colors.red} onPress={() => onPressHome()}></AppButton>
                      </View>
                    </View>
                  } 
                  {(paymentStep === StepType.Error) &&
                    <View style={{ height: 170 }}>
                      <Text style={{ ...TextStyles.heading, color: Colors.red, textAlign: 'center' }}>Payment Failed</Text>
                      <View style={{ paddingTop: Sizes.large }}>
                        <Text style={{ ...TextStyles.paragraph, marginHorizontal: 'auto', textAlign: 'center' }}>{errorMsg}</Text>
                      </View>
                      <View style={{ paddingTop: Sizes.medium }}>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, marginHorizontal: 'auto', textAlign: 'center' }}>Please retry the payment once again.</Text>
                      </View>
                      <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.extraLarge, marginHorizontal: 'auto' }}>
                        <AppButton text="Try Again" color={Colors.red} onPress={() => onPressHome()}></AppButton>
                      </View>
                    </View>
                  } 
                </AppModal>
              </View>
            </ScrollView>
          }
        </>
      }
    </>
  )
}
