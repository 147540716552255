import { ScrollView, View, Text, SafeAreaView, RefreshControl, Pressable } from 'react-native';
import { AppIconButton } from '../components/AppIconButton';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import TextStyles from '../constants/TextStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import { PlanDetailsCard } from '../components/PlanDetailsCard';
import Sizes from '../constants/Sizes';
import React, { useCallback, useEffect, useState } from 'react';
import { PlanDataUsageCard } from '../components/PlanDataUsageCard';
import { PlanExtensionCard } from '../components/PlanExtensionCard';
import { PlanTimeUsageCard } from '../components/PlanTimeUsageCard';
import { AppHeader } from '../components/AppHeader';
import { AppFooter } from '../components/AppFooter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import Heights from '../constants/Heights';
import useIsMobile from '../hooks/useIsMobile';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppMoreInfo } from '../components/AppMoreInfo';
import { AppButton } from '../components/AppButton';
import FlexStyles from '../constants/FlexStyles';
import { AppGap } from '../components/AppGap';
import { planService } from '../services/PlanService';
import { setAvailablePlans, setLoggedIn, setSelectedAccount, setAccounts, setXdrId } from '../store/user';
import { profileService } from '../services/ProfileService';
import { tokenService } from '../services/TokenService';

export default function HomeScreen({ navigation }: RootTabScreenProps<'Home'>) {
  const marginHorizontal = useHorizontalContainerMargin();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const now = new Date();
  const planEndDate = (user.selectedAccount.expiry_date && user.selectedAccount.expiry_date === "") ? new Date() : new Date(user.selectedAccount.expiry_date);
  const [ planTermEndDate, setPlanTermEndDate ] = useState(new Date());
  const [ dataResetDate, setDataResetDate ] = useState(new Date());
  const planExpired = now > planEndDate;
  const [allowExtension, setAllowExtension] = useState(false);
  const totalMinutes = user.accounts[0].plan.price === 25 ? 0 : 300;
  const alreadyUnlimited = user.selectedAccount.unlimited_int_call;
  // const formattedEndDate = planEndDate.toISOString().split("T")[0];
  
  const [refreshing, setRefreshing] = useState(false);
  const planHasBeenChanged = (user.selectedAccount.future_plan && user.selectedAccount.future_plan?.end_date !== null && user.selectedAccount.future_plan?.start_date !== null);
  const is7daysPlan = user.selectedAccount.plan.term_days === 7;
  const planSuspended = user.selectedAccount.plan.end_date ? false : true;

  const endDateForIntlCalling = planSuspended ? "" : new Date(user.selectedAccount.plan.end_date.split('T')[0]);
  if(endDateForIntlCalling !== ""){
    endDateForIntlCalling.setDate(endDateForIntlCalling.getDate() + 1)
  }

  const getDaysRemaining = (start: Date, end: Date) => {
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  const lessThan2DaysRemaining = getDaysRemaining(new Date(), new Date(user.selectedAccount.plan.end_date)) < 2;
  const allowPlanChange = !planHasBeenChanged && !lessThan2DaysRemaining && !is7daysPlan;

  const onRefresh = useCallback(() => {
    setRefreshing(true);

    const getAccount = async () => {
      const response = await profileService.getAccountInfo({ data: {} })

      if (!response || !response.success || response.data.accounts.length === 0) {
        await tokenService.deleteAccessTokenAsync()
        await tokenService.deleteAccessTokenExpiryAsync()
        await tokenService.deleteRefreshTokenAsync()
        await tokenService.deleteRefreshTokenExpiryAsync()
        dispatch(setLoggedIn(false))
        setRefreshing(false)
        console.error(response.error)
        return;

      } else {
        const account = response.data.accounts[0]
        dispatch(setAccounts(response.data.accounts))
        dispatch(setSelectedAccount(account))
        setRefreshing(false)
      }
    }

    getAccount().catch(err => console.error(err))

  }, []);

  // If it's more than 30 days before the term's end date,
  // we can assume that extension is either not allowed or the user has set-up autopay.
  // 7 days plans also cannot have extensions.
  const checkAllowExtension = () => {
    let thirtyDaysBeforeEndDate = new Date(planEndDate.toLocaleDateString('en-US'));
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    let now = new Date();
    if (thirtyDaysBeforeEndDate < now) {
      setAllowExtension(true); 
    }
  }

  const calculateEndDate = () => {
    if(!allowExtension){
      let expiryDate = new Date(planEndDate.toLocaleDateString('en-US'));
      let calculatedEndDate = new Date(expiryDate.setDate(expiryDate.getDate() - 30));
      setPlanTermEndDate(calculatedEndDate);
    }

    if(user.selectedAccount.plan.reset_date){
      let resetDate = new Date(user.selectedAccount.plan.reset_date);
      let calculatedEndDate = new Date(resetDate.setDate(resetDate.getDate() - 30));
      setDataResetDate(calculatedEndDate);
    }
  }

  useEffect(() => {
    const getDefaultPlans = async () => {
      var response = await planService.getDefaultPlans({ 
        data: {} 
      });
  
      if (!response || !response.success) {
        console.error(response.error);
      }
      if (response && response.success) {
        dispatch(setAvailablePlans(response.data.product_list));
      }
    }
    getDefaultPlans().catch(err => console.error(err));  
    checkAllowExtension();
    calculateEndDate();
  }, []);

  return (
    <>
      {!isMobile &&
        <>
          <AppHeader></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginRight: 20 }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Account Information</Text>
                  <PlanDetailsCard
                    accountName={user.selectedAccount.first_name}
                    accountNumber={user.selectedAccount.account_number}
                    phoneNumber={user.selectedAccount.phone_number}
                    currency={user.selectedAccount.plan.currency}
                    data={user.selectedAccount.plan.data}
                    dataUnit={user.selectedAccount.plan.data_unit}
                    endDate={planEndDate}
                    interval={user.selectedAccount.plan.interval}
                    price={user.selectedAccount.plan.price}
                    details={user.selectedAccount.plan.details}
                    is7daysPlan={is7daysPlan} />
                </View>
                {!planExpired && !planSuspended &&
                  <View style={{ marginRight: 20 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Data Usage</Text>
                    <PlanDataUsageCard 
                      dataRemaining={user.selectedAccount.plan.data_remaining}
                      dataUsedPercentage={user.selectedAccount.plan.data_used_percentage} 
                      dataUsed={user.selectedAccount.plan.data_used} 
                      dataUsedUnit={user.selectedAccount.plan.data_used_unit} 
                      totalData={user.selectedAccount.plan.data_total} 
                      totalDataUnit={user.selectedAccount.plan.data_unit} 
                      resetDate={dataResetDate} 
                      onPress={() => navigation.navigate('AddData')} 
                      is7daysPlan={is7daysPlan} />
                  </View>
                }
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ marginLeft: 20 }}>
                  {!is7daysPlan &&
                    <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Extension</Text>
                  }
                  {is7daysPlan &&
                    <View style={{ marginTop: Sizes.large, marginBottom: Sizes.medium }}>
                      <AppGap size={29} direction='vertical' />
                    </View>
                  }
                  <PlanExtensionCard 
                    endDate={planEndDate}
                    termEndDate={allowExtension ? planEndDate : planTermEndDate}
                    price={user.selectedAccount.plan.price}
                    allowExtension={allowExtension}
                    allowPlanChange={allowPlanChange}
                    is7daysPlan={is7daysPlan}
                  ></PlanExtensionCard>
                </View>
                {!planExpired && !is7daysPlan && !planSuspended &&
                  <View style={{ marginLeft: 20 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>International Calling</Text>
                    <PlanTimeUsageCard 
                      minutesUsed={user.selectedAccount.plan.minutes_used} 
                      totalMinutes={totalMinutes} 
                      onPress={() => navigation.navigate('AddData')} 
                      alreadyUnlimited={alreadyUnlimited} 
                      expiryDate={endDateForIntlCalling}
                      is25plan={user.selectedAccount.plan.price === 25}
                      iCustomer={user.selectedAccount.customer_info.customer_id}
                    />     
                  </View>
                }
              </View>
            </View>
            <AppGap direction='vertical' size={100}></AppGap>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <SafeAreaView style={{ backgroundColor: isMobile ? Colors.green : Colors.offwhite, flex: 1 }}>
          <ScrollView style={{ backgroundColor: isMobile ? Colors.green : Colors.offwhite }} refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh}></RefreshControl>
          }>
            <View style={{ ...PageLayoutStyles.container, backgroundColor: Colors.green }}>
              <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ backgroundColor: Colors.green }}>
                  <Text style={{ ...TextStyles.title, color: Colors.white }}>Hello,</Text>
                  <Text style={{ ...TextStyles.title, color: Colors.white, marginTop: -Sizes.medium }}>{user.selectedAccount.first_name}</Text>
                  <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: -Sizes.small }}>{user.selectedAccount.phone_number}</Text>
                  <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white }}>Account #: {user.selectedAccount.account_number}</Text>
                </View>
                <View style={{ marginTop: 15, backgroundColor: Colors.green }}>
                  <AppIconButton name="bell" onPress={() => navigation.navigate('Notifications')}></AppIconButton>
                </View>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: Sizes.extraLarge, marginBottom: Sizes.medium }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>Plan</Text>
                <AppMoreInfo info={['In order to port out, your line must be active.', 'You also have to provide your account number and PIN number to your new mobile provider. The PIN number is the last 4 digits of your phone number.']}></AppMoreInfo>
              </View>
              <PlanDetailsCard
                accountName={user.selectedAccount.first_name}
                accountNumber={user.selectedAccount.account_number}
                phoneNumber={user.selectedAccount.phone_number}
                currency={user.selectedAccount.plan.currency}
                data={user.selectedAccount.plan.data}
                dataUnit={user.selectedAccount.plan.data_unit}
                endDate={planEndDate}
                interval={user.selectedAccount.plan.interval}
                price={user.selectedAccount.plan.price}
                details={user.selectedAccount.plan.details}
                is7daysPlan={is7daysPlan} />
              {!planExpired && !planSuspended &&
                <>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: Sizes.extraLarge, marginBottom: Sizes.medium }}>
                    <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>Data Usage</Text>
                    <AppMoreInfo info={['The data shown is the total remaining data which can be used during your prepaid term cycle. The total data remaining includes an accumulation of your available data, plus any data add-on (top up).']}></AppMoreInfo>
                  </View>
                  <PlanDataUsageCard 
                    dataRemaining={user.selectedAccount.plan.data_remaining} 
                    dataUsedPercentage={user.selectedAccount.plan.data_used_percentage} 
                    dataUsed={user.selectedAccount.plan.data_used} 
                    dataUsedUnit={user.selectedAccount.plan.data_used_unit} 
                    totalData={user.selectedAccount.plan.data_total} 
                    totalDataUnit={user.selectedAccount.plan.data_unit} 
                    resetDate={dataResetDate} 
                    onPress={() => navigation.navigate('AddData')} 
                    is7daysPlan={is7daysPlan} ></PlanDataUsageCard>                
                </>
              }
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: Sizes.extraLarge, marginBottom: Sizes.medium }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>{is7daysPlan ? ' ' : 'Extension'}</Text>
                <AppMoreInfo info={['You are able to retrieve your phone number up to 90 days after your term expiration date (term end date).', 'There is no reactivation fee within the first 30 days after the expiration.', 'After 30 days, the reactivation fee (service charge) will be USD 10 on top of your plan fee.']}></AppMoreInfo>
              </View>
              <PlanExtensionCard 
                endDate={new Date(user.selectedAccount.expiry_date)} 
                termEndDate={allowExtension ? new Date(user.selectedAccount.expiry_date) : planTermEndDate}
                price={user.selectedAccount.plan.price}
                allowExtension={allowExtension}
                allowPlanChange={allowPlanChange}
                is7daysPlan={is7daysPlan}
              ></PlanExtensionCard>

              {/* Uncomment and implement in Phase 2 */}
              {((!user.selectedAccount.customer_info.auto_payment && allowExtension) || planExpired) && !is7daysPlan &&
                <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                  <View style={{marginRight: 4}}>
                    <AppButton text="Extend" onPress={() => navigation.navigate('ExtendYourPlan')}></AppButton>
                  </View>
                  {allowPlanChange && 
                    <View style={{marginLeft: 4}}>
                      <AppButton text="Plan Change" onPress={() => navigation.navigate('ChangeYourPlan')}></AppButton>
                    </View>
                  }
                </View>
              }
              {(user.selectedAccount.customer_info.auto_payment && allowPlanChange) &&
                <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                  <AppButton text="Plan Change" onPress={() => navigation.navigate('ChangeYourPlan')}></AppButton>
                </View>
              }
              {!planExpired && !is7daysPlan && !planSuspended &&
                <>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: Sizes.extraLarge, marginBottom: Sizes.medium }}>
                    <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>International Calling</Text>
                    <AppMoreInfo info={["Your access to international calls will be blocked once you exceed 300 complimentary international minutes for all plans that include international calling.", "You can add unlimited international calling to 25 countries for 10 USD + taxes. This feature is valid for your current plan term."]}></AppMoreInfo>
                  </View>
                  <PlanTimeUsageCard 
                    minutesUsed={user.selectedAccount.plan.minutes_used} 
                    totalMinutes={totalMinutes} 
                    onPress={() => navigation.navigate('InternationalCalling')} 
                    alreadyUnlimited={alreadyUnlimited} 
                    expiryDate={endDateForIntlCalling} 
                    is25plan={user.selectedAccount.plan.price === 25}
                    iCustomer={user.selectedAccount.customer_info.customer_id}
                  />
                </>
              }
            </View>
          </ScrollView >
        </SafeAreaView>
      }
    </>
  );
}