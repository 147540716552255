import { useEffect, useState } from 'react';
import { ScrollView, View, Text, Pressable } from 'react-native';
import { AppButton } from '../components/AppButton';
import { AppModal } from '../components/AppModal';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps } from '../types';
import { productService } from '../services/ProductService';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import CommonStyles from '../constants/CommonStyles';
import { AppPrice } from '../components/AppPrice';
import IconSizes from '../constants/IconSizes';
import { setExtensionDate, setExtensionType, setPaymentAmount, setPlanChangeName, setPlanChangeRecurringIProduct, setPlanChangeServiceIProduct } from '../store/user';
import { ExtensionType } from '../types/enums/ExtensionType';
import { customerService } from '../services/CustomerService';

type PlanChangeDetailsCardProps = {
  price: number,
  interval: string,
  data: number,
  dataUnit: string,
  currency: string,
  details: string[],
  iSubscription: number, 
}

export default function ChangeYourPlanScreen({ navigation }: RootStackScreenProps<'ChangeYourPlan'>) {
  const [modalVisible, setModalVisible] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  // TODO: these are only used for display. either use them or remove them
  // const [planChangeTaxes, setPlanChangeTaxes] = useState(0);
  // const [planChangeTotal, setPlanChangeTotal] = useState(0);
  const [planDetailsVisibleISubscription, setPlanDetailsVisibleISubscription] = useState<number | undefined>(undefined);
  const [selectedPlanISubscription, setSelectedPlanISubscription] = useState<number | undefined>(undefined);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(0);
  const selectedPlan = user.availablePlans.find(plan => plan.i_subscription === selectedPlanISubscription);


  let newEndDate = new Date(user.selectedAccount.plan.end_date);
  newEndDate.setDate(newEndDate.getDate() + 30);
  const formattedDateString = newEndDate.toISOString().split('T')[0];

  const handleTaxes = async (price: number, subscription: number) => {
    var response = await customerService.estimateTaxes({
      data: {
        calc_taxes: 1,
        i_customer: user.selectedAccount.customer_info.customer_id,
        xdr_list: [
          {
            charged_amount: price,
            i_dest: 17,
            i_service: 4,
            i_subscription: subscription
          }
        ]
      }
    })
    if (!response || !response.success) {
      console.error(response.error);
      return;
    }
    if (response && response.success) {
      let cumulativeTax = 0;
      if (response.data.taxes_list) {
        response.data.taxes_list.forEach((entry) => {
          cumulativeTax = cumulativeTax + entry.amount
        })
      }
      const newPlanChangeTotal = +(cumulativeTax + price).toFixed(2);
      dispatch(setPaymentAmount(newPlanChangeTotal));
      return;        
    }
  }

  useEffect(() => {
    const getProductList = async (selectedPlanName: string) => {
      var response = await productService.getProductList({ data: {
        search: selectedPlanName,
      }})
  
      if (!response || !response.success) {
        console.error(response.error);
      }
      if (response && response.success) {
        // should only have one item
        if (response.data.product_list[0].i_product) {
          dispatch(setPlanChangeServiceIProduct(response.data.product_list[0].i_product));
        }
      }
    }
    if (selectedPlan) {
      // convert plan name from 're' to 'se' to find the 'service_i_product' parameter.
      let searchName = selectedPlan.name.substring(0, selectedPlan.name.length - 2) + "se";
      getProductList(searchName).catch(err => console.error(err));
      // the current selectedPlan contains the 'recurring_i_product' paramter.
      dispatch(setPlanChangeRecurringIProduct(selectedPlan.i_product));
    }
    if (selectedPlanISubscription) {
      handleTaxes(selectedPlanPrice, selectedPlanISubscription).catch(err => console.error(err));
    }
  }, [selectedPlan]);

  const onPressYes = () => { 
    if (selectedPlan) {
      let planName = `${selectedPlan.end_user_name.split("_")[2]} ${selectedPlan.end_user_name.split("_")[3]}`
      dispatch(setPlanChangeName(planName));
      dispatch(setExtensionType(ExtensionType.PlanChange));
      dispatch(setExtensionDate(formattedDateString));
      navigation.navigate("MakeAPayment"); 
      setModalVisible(false);
    } 
  }

  const PlanChangeDetailsCard = (props: PlanChangeDetailsCardProps) => {
    const isSelected = selectedPlan?.i_subscription === props.iSubscription;
    const planDetailsVisible = planDetailsVisibleISubscription === props.iSubscription; 

    const onSelectPlan = () => {
      setSelectedPlanISubscription(props.iSubscription);
      setSelectedPlanPrice(props.price);
    }

    const onPressDetails = () => {
      if (planDetailsVisible) {
        setPlanDetailsVisibleISubscription(undefined);
      } else {
        setPlanDetailsVisibleISubscription(props.iSubscription);
      }
    }

    return (
      <View style={{ ...CommonStyles.card, padding: Sizes.none, backgroundColor: 'transparent', borderColor: Colors.white, borderWidth: 2 }}>
        <Pressable onPress={() => onSelectPlan()} style={{ ...CommonStyles.card, backgroundColor: isSelected ? Colors.green : Colors.white, borderBottomColor: Colors.white, borderBottomWidth: 2, padding: Sizes.medium, paddingHorizontal: Sizes.large, borderTopLeftRadius: Sizes.small, borderTopRightRadius: Sizes.small }}>
          <View style={{ ...FlexStyles.rowHorizontalCenter, justifyContent: 'space-between' }}>
            <Text style={{ ...TextStyles.title, color: isSelected ? Colors.white : Colors.green }}>{props.data} {props.dataUnit}</Text>
            <View style={{ ...FlexStyles.rowVerticalCenter }}>
              <AppPrice price={props.price} interval={props.interval} color={isSelected ? Colors.white : Colors.green} currency="USD"></AppPrice>
            </View>
          </View>
        </Pressable>
        <Pressable onPress={() => onPressDetails()}>
          {planDetailsVisible &&
            <View style={{ paddingHorizontal: Sizes.large, paddingTop: Sizes.medium }}>
              {props.details.map((d, i) => 
                  <View key={i} style={{flex: 1, flexDirection: 'row', marginTop: Sizes.small }}>
                    <FontAwesome name="circle" size={8} color={Colors.white} style={{ marginRight: 15, marginTop: 4 }}></FontAwesome>
                    <Text style={{color: Colors.white }}>{d}</Text>
                  </View>
              )}
            </View>
          }
          <View style={{ ...FlexStyles.rowHorizontalCenter }}>
            <FontAwesome name={planDetailsVisible ? 'angle-up' : 'angle-down'} size={IconSizes.large} color={Colors.white}></FontAwesome>
          </View>
        </Pressable>
      </View>
    )
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Plan</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large }}>Change your plan</Text>
        {user.availablePlans.filter(plan => plan.i_subscription !== user.selectedAccount.subscription && plan.i_product !== 55).map((plan, i) => {
          let parsedPlan = plan.end_user_name.split("_");
          let unit = parsedPlan[3]
          let dataAmt = +parsedPlan[2];
          let cost = +parsedPlan[0];
          let description = plan.description.split('\n');
          return (
            <View key={i} style={{ marginTop: Sizes.large }}>
              <PlanChangeDetailsCard currency="USD" data={dataAmt} dataUnit={unit} interval="/mo" price={cost} details={description} iSubscription={plan.i_subscription}/>
            </View>
          )
        })}
        <View style={{ marginTop: Sizes.large }}>
          <AppButton text="Change Plan" onPress={() => { setModalVisible(true) }} color={Colors.white} outline></AppButton>
        </View>
        <AppModal onClose={() => setModalVisible(false)} visible={modalVisible}>
          <Text style={{ ...TextStyles.subtitle, color: Colors.black, marginTop: Sizes.large, textAlign: 'center' }}>Confirmation</Text>
          <Text style={{ ...TextStyles.subtitle, color: Colors.black, marginTop: Sizes.large, textAlign: 'center', fontFamily: FontFamilys.regular }}>Are you sure you want to change your plan?</Text>
          <View style={{ ...FlexStyles.rowHorizontalCenter, marginTop: Sizes.large }}>
            <AppButton text="Yes" onPress={() => onPressYes()}></AppButton>
          </View>
          <View style={{ ...FlexStyles.rowHorizontalCenter, marginTop: Sizes.large }}>
            <AppButton text="Cancel" onPress={() => { setModalVisible(false); }}></AppButton>
          </View>
        </AppModal>
      </View>
    </ScrollView>
  );
}