import { FontAwesome } from '@expo/vector-icons';
import { useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import IconSizes from '../constants/IconSizes';
import TextStyles from '../constants/TextStyles';
import { AppPrice } from './AppPrice';
import Sizes from '../constants/Sizes';
import useIsMobile from '../hooks/useIsMobile';
import { AppCard } from './AppCard';

type PlanDetailsCardProps = {
  data: number;
  dataUnit: string;
  price: number;
  currency: string;
  interval: string;
  endDate?: Date;
  details: string[];
  accountName?: string;
  accountNumber?: string;
  phoneNumber?: string;
  is7daysPlan: boolean;
}

export function PlanDetailsCard(props: PlanDetailsCardProps) {
  const isMobile = useIsMobile();
  const now = new Date();
  const endDate = new Date(props.endDate || "")
  const planExpired = now > endDate;

  const [planDetailsVisible, setPlanDetailsVisible] = useState(false);
  return (
    <>
      {!isMobile &&
        <AppCard helpTitleText="Additional Plan Information" helpText={['In order to port out, your line must be active.', 'You also have to provide your account number and PIN number to your new mobile provider.', 'The PIN number is the last 4 digits of your phone number.']}>
          <View>
            <View style={{ padding: Sizes.large }}>
              <Text style={{ fontFamily: FontFamilys.regular, fontSize: 30 }}>Hello, {props.accountName}</Text>
              <Text style={{ fontSize: 14, letterSpacing: 0.28, color: Colors.green, fontFamily: FontFamilys.regular }}>{props.phoneNumber} | Account #: {props.accountNumber}</Text>
            </View>
            <View style={{ backgroundColor: Colors.green, borderBottomRightRadius: planDetailsVisible ? 0 : 8, borderBottomLeftRadius: planDetailsVisible ? 0 : 8, padding: 20 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={{ ...TextStyles.title, color: Colors.white }}>{props.data} {props.dataUnit}</Text>
                <AppPrice price={props.price} interval={props.is7daysPlan ? '/week' : props.interval} color={Colors.white} currency="USD"></AppPrice>
              </View>
              <View style={{ justifyContent: props.endDate ? 'space-between' : 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                {(props.endDate && !planExpired) &&
                  <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.regular, color: Colors.white }}>Expires on: {props.endDate.toLocaleDateString('en-US')}</Text>
                }
                {planExpired &&
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.regular, color: Colors.red }}>
                      Plan Expired
                    </Text>
                    <FontAwesome style={{ color: Colors.red, paddingLeft: Sizes.small }} size={14} name={'exclamation-triangle'} ></FontAwesome>
                  </View>
                }
                <Pressable onPress={() => setPlanDetailsVisible(!planDetailsVisible)}>
                  <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginRight: 10 }}>{planDetailsVisible ? 'See less' : 'Details'}</Text>
                    <View style={{ width: 22, height: 22, borderRadius: 22, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', }}>
                      <FontAwesome style={{ marginStart: !planDetailsVisible ? 2 : 0 }} name={planDetailsVisible ? 'angle-down' : 'angle-right'} color={Colors.green} size={20}></FontAwesome>
                    </View>
                  </View>
                </Pressable>
              </View>
            </View>
            {planDetailsVisible &&
              <View style={{ borderTopColor: Colors.offwhite, borderTopWidth: 1, backgroundColor: Colors.green, paddingHorizontal: Sizes.large, paddingVertical: Sizes.medium, borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}>
                {props.details.map((d, i) =>
                  <View key={i} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: i + 1 === props.details.length ? 0 : Sizes.small }}>
                    <FontAwesome name="circle" size={8} color={Colors.white} style={{ marginRight: 15, marginTop: Sizes.small }}></FontAwesome>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginTop: Sizes.small }}>
                      {d}
                    </Text>
                  </View>
                )}
              </View>
            }
          </View>
        </AppCard>
      }
      {isMobile &&
        <View style={{ ...CommonStyles.card, padding: Sizes.none, backgroundColor: 'transparent', borderColor: Colors.white, borderWidth: 2 }}>
          <View style={{ ...CommonStyles.card, padding: Sizes.medium, paddingHorizontal: Sizes.large, borderTopLeftRadius: Sizes.small, borderTopRightRadius: Sizes.small }}>
            <View style={{ ...FlexStyles.rowHorizontalCenter, justifyContent: 'space-between' }}>
              <Text style={{ ...TextStyles.title, color: Colors.green }}>{props.data} {props.dataUnit}</Text>
              <View style={{ ...FlexStyles.rowVerticalCenter }}>
                <AppPrice price={props.price} interval={props.is7daysPlan ? '/week' : props.interval} color={Colors.green} currency="USD"></AppPrice>
              </View>
            </View>
            {(props.endDate && !planExpired) &&
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.green }}>Expires on: {props.endDate.toLocaleDateString('en-US')}</Text>
            }
            {planExpired &&
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.regular, color: Colors.red }}>
                  Plan Expired
                </Text>
                <FontAwesome style={{ color: Colors.red, paddingLeft: Sizes.small }} size={14} name={'exclamation-triangle'} ></FontAwesome>
              </View>
            }
          </View>
          <Pressable onPress={() => setPlanDetailsVisible(!planDetailsVisible)}>
            {planDetailsVisible &&
              <View style={{ paddingHorizontal: Sizes.large, paddingTop: Sizes.medium }}>
                {props.details.map((d, i) => 
                    <View key={i} style={{flex: 1, flexDirection: 'row', marginTop: Sizes.small }}>
                      <FontAwesome name="circle" size={8} color={Colors.white} style={{ marginRight: 15, marginTop: 4 }}></FontAwesome>
                      <Text style={{color: Colors.white }}>{d}</Text>
                    </View>
                )}
              </View>
            }
            <View style={{ ...FlexStyles.rowHorizontalCenter }}>
              <FontAwesome name={planDetailsVisible ? 'angle-up' : 'angle-down'} size={IconSizes.large} color={Colors.white}></FontAwesome>
            </View>
          </Pressable>
        </View>
      }
    </>

  );
}