import { CardType } from "../types/enums/CardType";

/**
   * Returns strings with only numbers in it.
   * @param value 
   * @returns 
   */
export const numbersOnly = (value: string) => value.replace(/\D/g, "");
/**
   * Extracts expiration date parts from a string in this format:
   * MM/YY
   * @param value 
   * @returns
   */
export const extractExpirationDate = (value: string): { expirationMonth: string, expirationYear: string } => {
   let parts = value.split('/');
   return parts.length !== 2 ? { expirationMonth: '', expirationYear: '' } : { expirationMonth: parts[0], expirationYear: `20${parts[1]}` };
}

/**
 * Checks a credit card number for its type used with ProxyApi Portaone endpoints.
 * @param cc 
 * @returns 
 */
export const getCreditCardType = (cc: string): CardType => {
   let amex = new RegExp('^3[47][0-9]{13}$');
   let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
   let mastercard = new RegExp('^5[1-5][0-9]{14}$');
   let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
   if (visa.test(cc)) {
      return CardType.VISA;
   }
   if (amex.test(cc)) {
      return CardType.AMEX;
   }
   if (mastercard.test(cc) || mastercard2.test(cc)) {
      return CardType.MasterCard;
   }
   return CardType.VISA;
};

/**
 * Checks a credit card number for its type and string used with ProxyApi Portaone endpoints.
 * @param cc 
 * @returns 
 */
export const getCreditCardString = (cc: string): string => {
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  if (visa.test(cc)) {
     return 'VISA';
  }
  if (amex.test(cc)) {
     return 'American Express';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
     return 'MasterCard';
  }
  return 'VISA';
};

export const submitAsForm = (path: string, params: any, method = 'post') => {
   const form = document.createElement('form');
   form.method = method;
   form.action = path;
   for (const key in params) {
       if (params.hasOwnProperty(key)) {
           const hiddenField = document.createElement('input');
           hiddenField.type = 'hidden';
           hiddenField.name = key;
           hiddenField.value = params[key];
           form.appendChild(hiddenField);
       }
   }
   document.body.appendChild(form);
   form.submit();

}
