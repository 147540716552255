import { GestureResponderEvent, Pressable, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import TextStyles from '../constants/TextStyles';
import { AppContainer } from './AppContainer';
import { AppRow } from './AppRow';
import { AppColumn } from './AppColumn';
import { useEffect, useState } from 'react';
import { AppModal } from './AppModal';
import FontFamilys from '../constants/FontFamilys';
import useIsMobile from '../hooks/useIsMobile';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import Sizes from '../constants/Sizes';
import { AppButton } from './AppButton';
import FlexStyles from '../constants/FlexStyles';
import { StepType } from '../types/enums/StepType';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { setExtensionDate, setExtensionType, setInternationalCallingPaymentAmount } from '../store/user';
import { ExtensionType } from '../types/enums/ExtensionType';
import { AppCard } from './AppCard';
import { AppBoldText } from './AppBoldText';
import { customerService } from '../services/CustomerService';

type PlanTimeUsageCardProps = {
  minutesUsed: number;
  totalMinutes: number;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  alreadyUnlimited: boolean;
  expiryDate: Date | string;
  is25plan: boolean | undefined;
  iCustomer: number;
}

export function PlanTimeUsageCard(props: PlanTimeUsageCardProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  const isMobile = useIsMobile();
  const [step, setStep] = useState(StepType.First);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState(0);
  const fee = 10;
  const total = taxes + fee;
  const minutesText = props.totalMinutes === 0 ? "You don't have any minutes in your plan." : props.totalMinutes;

  const onPressAddUnlimitedCalling = () => {
    setStep(StepType.Second);
  }

  const onPressConfirm = () => {
    setStep(StepType.First)
    dispatch(setExtensionType(ExtensionType.InternationalCalling));
    const extensionDate = props.expiryDate.toString();
    dispatch(setExtensionDate(extensionDate));
    dispatch(setInternationalCallingPaymentAmount(total));
    navigation.navigate('MakeAPayment');
  }

  const onClickCancel = () => {
    setStep(StepType.First)
  }

  useEffect(() => {
    const estimateTaxes = async () => {
      setLoading(true);
      const taxResponse = await customerService.estimateTaxes({
        data: {
          calc_taxes: 1,
          i_customer: props.iCustomer,
          xdr_list: [
            {
              charged_amount: 10,
              i_dest: 18,
              i_service: 4,
              i_subscription: 9
            }
          ]
        }
      })

      if (!taxResponse.data || !taxResponse.success) {
        console.log("Something went wrong with estimate taxes");
        setLoading(false);
      }

      if (taxResponse && taxResponse.success) {
        let taxAcc = 0;
        if (taxResponse.data.taxes_list) {
          taxResponse.data.taxes_list.forEach(e => {
            taxAcc += e.amount;
          })
        }
        setTaxes(taxAcc);
        setLoading(false);
      }
    }
    estimateTaxes().catch(err => console.error(err));
  }, []);

  return (
    <>
      {!isMobile &&
        <>
        <AppCard helpText={["Your access to international calls will be blocked once you exceed 300 complimentary international minutes for all plans that include international calling.", "You can add unlimited international calling to 25 countries for 10 USD + taxes. This feature is valid for your current plan term."]}>
          <View style={CommonStyles.cardBody}>
            {!props.alreadyUnlimited &&
              <>
                {!props.is25plan &&
                  <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}>Your data will be reset on: <AppBoldText>{props.expiryDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</AppBoldText></Text>
                }
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: Sizes.medium, borderRightWidth: 2, borderRightColor: Colors.lightGrey }}>
                        <Text style={{ ...TextStyles.paragraph }}>Plan Minutes</Text>
                        {typeof minutesText === 'number' &&
                          <Text style={{ ...TextStyles.title, color: Colors.green }}>{props.totalMinutes}</Text>
                        }
                        {typeof minutesText === 'string' &&
                          <Text style={{ ...TextStyles.paragraph, color: Colors.green, paddingTop: Sizes.small, textAlign: 'center' }}>{minutesText}</Text>
                        }
                      </View>
                    </AppColumn>
                    {/* <AppColumn>
                      <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: Sizes.medium, borderRightWidth: 2, borderRightColor: Colors.lightGrey }}>
                        <Text style={{ ...TextStyles.paragraph }}>Minutes Used</Text>
                        <Text style={{ ...TextStyles.title, color: Colors.green }}>{props.minutesUsed}</Text>
                      </View>
                    </AppColumn> */}
                    <AppColumn>
                      <Pressable onPress={() => setModalVisible(true)}>
                        <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: Sizes.medium }}>
                          <FontAwesome size={20} name="globe" color={Colors.grey}></FontAwesome>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.green, textAlign: 'center', paddingTop: Sizes.small }}>See the list of countries included</Text>
                        </View>
                      </Pressable>
                    </AppColumn>
                  </AppRow>
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginTop: Sizes.medium }}>
                    {step === StepType.First &&
                      <AppButton text="Add Unlimited Calling" onPress={onPressAddUnlimitedCalling}></AppButton>  
                    }
                    {step === StepType.Second &&
                      <View style={{ alignItems: 'center' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                          <Text>
                            Fee:
                          </Text>
                          <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}>
                            ${fee.toFixed(2)}
                          </Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                          <Text>
                            Taxes & Surcharges:
                          </Text>
                          <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}>
                            ${taxes.toFixed(2)}
                          </Text>
                        </View>
                        <Text style={{ ...TextStyles.subtitle, color: Colors.green, marginVertical: Sizes.medium }}>
                          Total: ${total.toFixed(2)} USD
                        </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: Sizes.large }}>
                          <Pressable style={{ paddingRight: 8 }} onPress={onClickCancel}>
                            <FontAwesome style={{ color: Colors.darkGrey }} size={30} name={'chevron-circle-left'}></FontAwesome>
                          </Pressable>
                          <AppButton text="Confirm" onPress={onPressConfirm} loading={loading}></AppButton>  
                        </View>
                      </View>
                    }
                  </View>
                </AppContainer>
              </>
            }
            {props.alreadyUnlimited &&
            <>
              <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}>Your data will be reset on: <AppBoldText>{props.expiryDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</AppBoldText></Text>
              <AppContainer>
                <AppRow>
                  <AppColumn>
                    <View style={{ flexDirection: 'column', justifyContent: 'center', padding: Sizes.medium, borderRightWidth: 2, borderRightColor: Colors.lightGrey }}>
                      <Text style={{ ...TextStyles.paragraph }}>Plan Minutes</Text>
                      <Text style={{ fontSize: 30, fontFamily: FontFamilys.bold, color: Colors.green }} numberOfLines={1}>Unlimited</Text>
                    </View>
                  </AppColumn>
                  <AppColumn>
                    <Pressable onPress={() => setModalVisible(true)}>
                      <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: Sizes.medium }}>
                        <FontAwesome size={20} name="globe" color={Colors.grey}></FontAwesome>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.green, textAlign: 'center', paddingTop: Sizes.small }}>See the list of countries included</Text>
                      </View>
                    </Pressable>
                  </AppColumn>
                </AppRow>
              </AppContainer>
            </>
            }
          </View>
        </AppCard>
        </>
      }
      {isMobile &&
        <View style={{ ...CommonStyles.card }}>
          {!props.alreadyUnlimited &&
            <>
              {!props.is25plan &&
                <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}>Your data will be reset on: <AppBoldText>{props.expiryDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</AppBoldText></Text>
              }
              <View style={{ borderRadius: 10 }}>
                <View style={{ ...FlexStyles.rowCenter, padding: Sizes.medium }}>
                  <Text style={{ ...TextStyles.paragraph, marginRight: Sizes.medium }}>Minutes in your plan:</Text>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green, fontSize: Sizes.large }}>{props.totalMinutes}</Text>
                </View>
                <Pressable onPress={() => setModalVisible(true)}>
                  <View style={{ ...FlexStyles.rowCenter, borderTopColor: Colors.lightGrey, borderTopWidth: 1, padding: Sizes.medium }}>
                    <View style={{ marginRight: 10 }}>
                      <FontAwesome size={30} name="globe" color={Colors.grey}></FontAwesome>
                    </View>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.green }}>See the list of countries included</Text>
                  </View>
                </Pressable>
              </View>
              <View style={{ ...FlexStyles.rowCenter }}>
                <AppButton text="Add Unlimited Calling" onPress={props.onPress}></AppButton>
              </View>
            </>  
          }
          {props.alreadyUnlimited && 
            <>
              <View style={{ borderRadius: 10 }}>
                <Text style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}>Your data will be reset on: <AppBoldText>{props.expiryDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</AppBoldText></Text>
                <View style={{ ...FlexStyles.rowCenter, padding: Sizes.medium }}>
                  <Text style={{ ...TextStyles.paragraph, marginRight: Sizes.medium }}>Minutes in your plan:</Text>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green, fontSize: Sizes.large }}>Unlimited</Text>
                </View>
                <Pressable onPress={() => setModalVisible(true)}>
                  <View style={{ ...FlexStyles.rowCenter, borderTopColor: Colors.lightGrey, borderTopWidth: 1, padding: Sizes.medium }}>
                    <View style={{ marginRight: 10 }}>
                      <FontAwesome size={30} name="globe" color={Colors.grey}></FontAwesome>
                    </View>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.green }}>See the list of countries included</Text>
                  </View>
                </Pressable>
              </View>
            </>  
          }
        </View>
      }

      <AppModal onClose={() => setModalVisible(false)} visible={modalVisible} headerTitle="List of Countries" width={isMobile ? '' : '40%'} height={isMobile ? '40%' : ''}>
        <AppContainer>
          {!isMobile ? (
            <AppRow>
              <AppColumn>
                <Text style={{ ...TextStyles.paragraph }}>BANGLADESH</Text>
                <Text style={{ ...TextStyles.paragraph }}>BRAZIL</Text>
                <Text style={{ ...TextStyles.paragraph }}>CANADA</Text>
                <Text style={{ ...TextStyles.paragraph }}>CHINA</Text>
                <Text style={{ ...TextStyles.paragraph }}>COLOMBIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>FRANCE</Text>
                <Text style={{ ...TextStyles.paragraph }}>GERMANY</Text>
                <Text style={{ ...TextStyles.paragraph }}>HONG KONG</Text>
                <Text style={{ ...TextStyles.paragraph }}>INDIA</Text>
              </AppColumn>
              <AppColumn>
                <Text style={{ ...TextStyles.paragraph }}>INDONESIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>IRAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>JAPAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>KUWAIT</Text>
                <Text style={{ ...TextStyles.paragraph }}>MEXICO</Text>
                <Text style={{ ...TextStyles.paragraph }}>NEPAL</Text>
                <Text style={{ ...TextStyles.paragraph }}>NIGERIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>PAKISTAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>SAUDI ARABIA</Text>
              </AppColumn>
              <AppColumn>
                <Text style={{ ...TextStyles.paragraph }}>SOUTH KOREA</Text>
                <Text style={{ ...TextStyles.paragraph }}>SPAIN</Text>
                <Text style={{ ...TextStyles.paragraph }}>TAIWAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>TURKEY</Text>
                <Text style={{ ...TextStyles.paragraph }}>UNITED KINGDOM</Text>
                <Text style={{ ...TextStyles.paragraph }}>VENEZUELA</Text>
                <Text style={{ ...TextStyles.paragraph }}>VIETNAM</Text>
              </AppColumn>
            </AppRow>
            ) : (
            <AppRow>
              <AppColumn>
                <Text style={{ ...TextStyles.paragraph }}>BANGLADESH</Text>
                <Text style={{ ...TextStyles.paragraph }}>BRAZIL</Text>
                <Text style={{ ...TextStyles.paragraph }}>CANADA</Text>
                <Text style={{ ...TextStyles.paragraph }}>CHINA</Text>
                <Text style={{ ...TextStyles.paragraph }}>COLOMBIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>FRANCE</Text>
                <Text style={{ ...TextStyles.paragraph }}>GERMANY</Text>
                <Text style={{ ...TextStyles.paragraph }}>HONG KONG</Text>
                <Text style={{ ...TextStyles.paragraph }}>INDIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>INDONESIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>IRAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>JAPAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>KUWAIT</Text>
                <Text style={{ ...TextStyles.paragraph }}>MEXICO</Text>
              </AppColumn>
              <AppColumn>
                <Text style={{ ...TextStyles.paragraph }}>NEPAL</Text>
                <Text style={{ ...TextStyles.paragraph }}>NIGERIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>PAKISTAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>SAUDI ARABIA</Text>
                <Text style={{ ...TextStyles.paragraph }}>SOUTH KOREA</Text>
                <Text style={{ ...TextStyles.paragraph }}>SPAIN</Text>
                <Text style={{ ...TextStyles.paragraph }}>TAIWAN</Text>
                <Text style={{ ...TextStyles.paragraph }}>TURKEY</Text>
                <Text style={{ ...TextStyles.paragraph }}>UNITED KINGDOM</Text>
                <Text style={{ ...TextStyles.paragraph }}>VENEZUELA</Text>
                <Text style={{ ...TextStyles.paragraph }}>VIETNAM</Text>
              </AppColumn>
            </AppRow>
          )}
        </AppContainer>
      </AppModal>
    </>
  );
}