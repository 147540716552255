import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    moreInfo: [] as string[],
  },
  reducers: {
    setMoreInfo(state, action: PayloadAction<string[]>) {
      state.moreInfo = action.payload;
    }
  }
});

export const { setMoreInfo } = appSlice.actions;
export default appSlice.reducer;